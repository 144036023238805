import { once } from "lodash-es"
import {
  captureException,
  captureMessage,
  configureScope,
  init as SentryInit,
  setUser as setUser_,
  withScope,
} from "@sentry/browser"
import axios, { AxiosError } from "axios"

let sentryId: string | undefined = ""
let didInitialize = false

export function init(id: string) {
  if (!["dev", "staging", "production"].includes(window.HP_ENVIRONMENT)) {
    // eslint-disable-next-line no-console
    return console.log("HP_ENVIRONMENT is 'local'. Ignoring Sentry init.")
  }

  if (didInitialize) {
    throw new Error("Monitoring was already initialized")
  }

  if (
    process.env.NODE_ENV === "production" &&
    (id === undefined || id === "")
  ) {
    setTimeout(() => {
      throw new Error("You must provide an id to the monitor's init function")
    })
  }

  sentryId = id

  SentryInit({
    dsn: sentryId,
    environment: window.HP_ENVIRONMENT,
  })
  configureScope((s) => s.setTag("program", PROGRAM))

  didInitialize = true && !!sentryId
}

function addDetailsAndSend<
  Capture extends typeof captureException | typeof captureMessage
>(captureFn: Capture, message: Parameters<Capture>[0], additionalInfo?: IHash) {
  if (additionalInfo) {
    withScope((scope) => {
      scope.setExtras(additionalInfo)
      captureFn(message)
    })
  } else {
    captureFn(message)
  }
}

export function sendMessage(message: string, additionalInfo?: IHash) {
  if (didInitialize) {
    addDetailsAndSend(captureMessage, message, additionalInfo)
  } else {
    // eslint-disable-next-line no-console
    console.warn(
      `Sentry.captureMessage dev fallback: ${message}`,
      additionalInfo
    )
  }
}

/**
 * Pass an id to set the current user, else pass null to clear the user.
 */
export function setUser(id: Maybe<number>) {
  if (!didInitialize) return
  if (id === null) return setUser_(null)

  setUser_({ id: id.toString() })
}

/**
 * Placeholder, use @hpt/core/string.parseQueryString to clean this if/when needed.
 */
function sanitizeQs(s?: string) {
  if (!s) return ""
  return `?${s}`
}

const reNum = /\d+/g
const dirtyStrings = [
  ["users/password/", "users/password/{uuid}"],
  /**
   * There is a GET with this prefix which also gets matched, shrug, still need to prune the uuid.
   */
  ["users/invite/", "users/invite/{uuid}/complete"],
]
const replaceDirtyStrings = (acc: string, [v, prettyV]: string[]) =>
  acc.includes(v) ? prettyV : acc

const sanitizeResource = (s: string) =>
  dirtyStrings.reduce(replaceDirtyStrings, s).replace(reNum, "{id}")

/**
 * Remove data from the path
 */
export function sanitizePath(s?: string) {
  if (!s) return ""

  const [resource, queryString] = s.split("?")
  return sanitizeResource(resource) + sanitizeQs(queryString)
}

const NO_RESPONSE_STATUS_DEFAULT = "[no status code]"

/**
 * Being extremely cautious with presence of values since historically Axios has
 * failed to give us an error. At least in our interceptor it did.
 */
export function reportApiError(err?: AxiosError) {
  if (!err) {
    /**
     * TODO: remove this error and fix the type above if this error is never called in, oh, the next
     * half year.
     */
    reportError(Error("reportApiError was called without an Axios error."))
  }

  // In case we have missed an early return due to cancellation
  if (axios.isCancel(err)) return

  const c = err?.config
  const r = err?.response
  const statusNum = r?.status
  const statusString = r?.status.toString() ?? NO_RESPONSE_STATUS_DEFAULT
  const path = sanitizePath(c?.url)

  if (err && path) {
    err.message = `${c?.method?.toUpperCase()} ${path} failed with a ${statusString}`
  }

  if (statusNum === 401 || statusNum === 403) return

  withScope((scope) => {
    if (c?.params) {
      scope.setExtras({
        params: c?.params,
      })
    }

    if (r?.data) {
      let data = r.data
      delete data.path
      delete data.status
      delete data.timestamp
      scope.setExtras({
        responseBody: data,
      })
    }

    /**
     * Sentry will now fingerprint based on its usual algo, mainly the stack
     * trace which in this case isn't super useful, plus the passed url and
     * status. This helps to split up api errors into distinct groups in their
     * interface.
     */
    scope.setFingerprint(["{{ default }}", path, statusString])
    scope.setTags({
      statusCode: statusString,
      path,
      traceId: r?.headers?.["x-hp-trace-id"],
      contentType: r?.headers?.["content-type"],
      method: c?.method as string,
      accept: c?.headers?.Accept,
    })

    if (didInitialize) {
      captureException(err)
      // eslint-disable-next-line no-console
      console.error("Reported Error:", err)
    } else {
      // eslint-disable-next-line no-console
      console.error(`Sentry.captureException dev fallback:`, err)
    }
  })
}

export function reportError(err: Error, errInfo?: IHash) {
  if (didInitialize) {
    addDetailsAndSend(captureException, err, errInfo)
    // eslint-disable-next-line no-console
    console.error("Reported Error:", err, errInfo)
  } else {
    // eslint-disable-next-line no-console
    console.error(`Sentry.captureException dev fallback:`, err, errInfo)
  }
}

export function makeReportOnce<
  T extends typeof sendMessage | typeof reportError
>(type: T) {
  return once(type)
}
