export function initSegment() {
  if (!window.HP_SEGMENT_KEY) {
    return // Analytics API Key is not configured
  }

  if (!window.analytics) {
    return // Analytics library is not available
  }

  window.analytics.load(window.HP_SEGMENT_KEY)
}
