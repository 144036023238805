import moment from "moment"
import axios, { AxiosResponse, AxiosRequestConfig, AxiosStatic } from "axios"

export interface AxiosWrapper extends AxiosStatic {
  get<T = any, R = AxiosResponse<T>>(
    url: string,
    config?: ServerConfig
  ): Promise<R>
}
export interface ServerConfig extends AxiosRequestConfig {
  bustCache?: boolean
  reset?: boolean
}

let store = {}

export const TWELVE_HOURS = 5.76e7

type Cache = {
  createdAt: number
  expiresAt: number
  response: IDoNotKnow
}

//
// Data is valid for 12 hours or until end of the day, whichever comes first
const isValid = (cache: Cache) =>
  cache &&
  new Date().getTime() < cache.expiresAt &&
  moment(cache.createdAt).isSame(moment(), "day")

const cacheSuccess = (key: string) => (response: AxiosResponse) => {
  const now = new Date().getTime()

  store[key] = {
    createdAt: now,
    expiresAt: now + TWELVE_HOURS,
    response,
  }
  return response
}

function stringifyRequest(url: string, config: ServerConfig) {
  return config.params ? `${url}${JSON.stringify(config.params)}` : url
}

// Cache GET requests' response for 12 hours, keyed by path and query params
//
// Options (defaults shown):
//   bustCache: false    (skips checking for a cached response)
//
export const axios_: AxiosWrapper = new (function AxiosWrapper() {
  Object.keys(axios).forEach((key) => {
    this[key] = axios[key]
  })

  this.get = function (url: string, config: ServerConfig = {}) {
    const requestSignature = stringifyRequest(url, config)
    const cache = store[requestSignature]

    if (config.bustCache || !isValid(cache)) {
      const { bustCache, reset, ...axiosConfig } = config
      return axios.get(url, axiosConfig).then(cacheSuccess(requestSignature))
    } else {
      return Promise.resolve(cache.response)
    }
  }
})()

export function clearCache() {
  store = {}
}

export default axios_
