import "core-js"

import React from "react"
import ReactDOM from "react-dom"

import { axiosUtils, dom, initGlobalHelpers } from "@hpt/core"
import { init as initMonitor } from "@hpt/monitor"
import { initSegment } from "./lib/analytics"

if (PROGRAM !== "pear") {
  import("./styles/global.css")
} else {
  import("./styles/global-pear.css")
}

if (LOAD_DEV_TOOLS) {
  import("./lib/devTools")
}

const isDev = process.env.NODE_ENV === "development"

axiosUtils.setDefaults()
initMonitor(SENTRY_ID)

initSegment()

import(`./programs/${PROGRAM}.ts`).then(function (program) {
  const rootEl = document.getElementById("root")

  if (isDev || KEEP_CSS_VARS) {
    dom.injectTheme(program.theme)
  }

  const App = program.App
  ReactDOM.render(<App />, rootEl)
})

initGlobalHelpers()
