let passiveSupport: object | null = null

// adds { passive: true } to event listener options if supported
export function getListenerOptions(options = {}) {
  if (passiveSupport !== null) return { ...options, ...passiveSupport }

  try {
    window.addEventListener(
      "test",
      null as any,
      Object.defineProperty({}, "passive", {
        // eslint-disable-next-line getter-return
        get: function () {
          passiveSupport = { passive: true }
        },
      })
    )
  } catch (e) {
    passiveSupport = {}
  }
  return { ...options, ...passiveSupport }
}

export const doc =
  document.documentElement || document.body.parentNode || document.body

export function offsetFromViewportTop(
  el: Element,
  elementBoundary: "top" | "bottom" = "top"
) {
  return el.getBoundingClientRect()[elementBoundary]
}

function getScrollable() {
  function oldDetectionMethod() {
    // https://bugs.webkit.org/show_bug.cgi?id=121876 has been fixed, but the
    // fix broke our workaround. So this will only remain as a fallback
    // detection method for browsers that do not have the `scrollingElement`.
    // Though, apparently the `scrollingElement` was implemented as a workaround
    // for this original bug as early as Safari 9.
    const isSafari =
      /Safari/.test(navigator.userAgent) &&
      /Apple Computer/.test(navigator.vendor)
    return isSafari ? document.body : doc
  }

  // See https://dev.opera.com/articles/fixing-the-scrolltop-bug/
  return document.scrollingElement
    ? document.scrollingElement
    : oldDetectionMethod()
}

export const scrollable = getScrollable()

export function scrollTo(yPos: number) {
  scrollable.scrollTop = yPos
}

//
// Given an object of theme properties, convert to css
export function injectTheme(theme: IHash<string>) {
  const head = document.head
  const style = document.createElement("style")

  const styleBody = Object.keys(theme).reduce((acc, property) => {
    return acc + `${property}: ${theme[property]};\n`
  }, "")

  const themeInCss = `:root {\n${styleBody}\n}`

  style.appendChild(document.createTextNode(themeInCss))
  head.appendChild(style)
}

const divReplacer: Parameters<string["replace"]>[1] = (
  _,
  _open,
  children,
  _close
) => children
const reDiv = /(<div>)(.*)(<\/div>)/

/*
 * admin-ui returns some content as a naked, empty div, which we'll need to
 * check for content (mall product disclaimer header)
 */
export function divHasContent(s?: Maybe<string>): s is string {
  return !!(s && s.replace(reDiv, divReplacer))
}

export type Coordinates = { top: number; left: number }
export type TransformCoordinates<T = Element> = (
  x: Coordinates,
  el: T
) => Coordinates
/**
 * Get the top, left properties of an element based on the window
 *
 * Optionally, pass in a transformation function to change the result if the
 * result is a Coordinates object
 */
export function getCoordinatesFromWindow<T extends Nullish<Element>>(
  el: T,
  ...transforms: Array<TransformCoordinates<Definitely<T>>>
): T extends Element ? Coordinates : undefined {
  // I don't know what is going on with the types here. Types created by using
  // this function work perfectly, but the types in the body are confused. "any"
  // is to placate TS and "as Coordinates" is to re-establish type safety after
  // using "any"
  if (!el) return undefined as any

  const rect = el.getBoundingClientRect()
  let box: Coordinates = { top: rect.top, left: rect.left }

  for (const transform of transforms) {
    box = transform(box, el as Definitely<T>)
  }

  return box as any
}

const addDocOffsets = (c: Coordinates) => ({
  top: c.top + window.pageYOffset,
  left: c.left + window.pageXOffset,
})

const centerOfEl = (c: Coordinates, el: Element) => ({
  top: c.top + el.clientHeight / 2,
  left: c.left + el.clientWidth / 2,
})

export const coordinateTransforms = {
  centerOfEl,
}

/**
 * Get the top, left properties of an element based on the document
 */
export const getCoordinatesFromDocument = <T extends Nullish<Element>>(
  el: T,
  ...transforms: TransformCoordinates[]
) => getCoordinatesFromWindow(el, addDocOffsets, ...transforms)

export const coordsEqual = (a?: Coordinates, b?: Coordinates) =>
  a && b && a.top === b.top && a.left === b.left
