/**
 * These global functions are accessible on the window object for convenience in
 * testing, development, or maintenance. In the browser console, they are
 * available under the `HPT_Helpers` object.
 *
 * Note: These functions are available in all build environments.
 */
export interface HptGlobalHelpers {
  HPT_Helpers: {
    /**
     * This will be helpful in setting server content when it comes time to sync
     * local defs and what is actually being served
     */

    finalizedContentDef: () => void

    /**
     * When the idle timer is mounted, it will populate this method so that we
     * can cause an idle logout whenever we want. This will allow simpler
     * testing in Cypress.
     */
    invokeIdleLogout: () => void

    /**
     * Some global state to prevent the sticky footer from showing, even if its
     * internal logic says it should show. There is no option to force it to be
     * in view, only to force it to be hidden (disallow). This is helpful for
     * screenshots.
     */
    setAllowStickyFooter: (allow: boolean) => void
  }
}

/**
 * Logging to the console instead of returning the string prevents unnecessary
 * escape characters that we'd have to remove from our code after pasting.
 */
// eslint-disable-next-line no-console
const logForCopying = (x: any) => console.log(JSON.stringify(x))

export function initGlobalHelpers() {
  window.HPT_Helpers = {
    finalizedContentDef: () => logForCopying(window.HP_LOADED_CONTENT),
    invokeIdleLogout: () => {},
    setAllowStickyFooter: () => {},
  }
}

export function getGlobalHelpers() {
  if (!window.HPT_Helpers) {
    initGlobalHelpers()
  }
  return window.HPT_Helpers
}

export function setGlobalHelper<
  T extends keyof HptGlobalHelpers["HPT_Helpers"],
  U extends HptGlobalHelpers["HPT_Helpers"][T]
>(name: T, value: U) {
  window.HPT_Helpers = { ...getGlobalHelpers(), [name]: value }
}
