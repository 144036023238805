import server from "./server"

export function setDefaults() {
  server.defaults.baseURL = "/api/v1/consumer/"
  server.defaults.headers.common["Accept"] = "application/json"
  server.defaults.headers.post["Content-Type"] = "application/json"
}

export const setAuthHeaders = (s) => {
  server.defaults.headers.common["x-auth-token"] = s
}

let sessionInterceptor = null
export function setSessionInterceptor(sessionFn, history) {
  sessionInterceptor = server.interceptors.response.use(undefined, function (
    error
  ) {
    // there seems to be a bug in axios where sometimes the response is excluded on the error,
    // as detected by a live program.
    if (!error.response) return Promise.reject(error)

    const {
      response: { status },
    } = error

    /**
     * We expect 403s when a user is authenticated via a magic link but tries to
     * do an operation which requires sign in authentication. This list will
     * surely change over time, but to give you an idea of what's off-limits via
     * magic links:
     *   content-resource/**
     *   content-therapeutic-areas/**
     *   event-reasons/**
     *   reengagement/**
     *   refills/**
     *   support-questions/**
     *   ui-state/**
     */
    if (status === 401 || status === 403) sessionFn(history)

    return Promise.reject(error)
  })
}

// Since Pretender prevents an actual network request, we cannot see unhandled
// errors (when our mock server actually encounters a real error) if they are
// subsequently (purposefully) swallowed/handled by our app
if (MOCK_SERVER) {
  server.interceptors.response.use(
    (success) => success,
    (error) => {
      console.error(error) // eslint-disable-line no-console
      return Promise.reject(error)
    }
  )
}

export function clearAuth() {
  delete server.defaults.headers.common["x-auth-token"]
  sessionInterceptor && server.interceptors.response.eject(sessionInterceptor)
}
